@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* General Styling */
body {
    font-family: "Barlow Condensed", sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .products-section {
    margin-top: 10vh;
    /* padding: 50px 20px; */
    background-color: #fff;
    text-align: center;
    height: 70vh;
  }
  
  .products-header h3 {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #000;
    text-align: center;
  }
  
  /* Container for the Product Info and Image */
  .products-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 0 auto;
    max-width: 1200px;
  }
  
  /* Product Image Styling */
  .product-image {
    flex: 1;
  }
  
  .product-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Product Information Styling */
  .product-info {
    font-family: "Barlow Condensed", sans-serif;
    flex: 1;
    text-align: center;
    color: #333;
  }
  
  .product-info .product-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }

  .product-info .product-title h3{
    color: #333;
  }
  
  .product-info .product-description {
    font-size: 18px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .product-info .product-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .product-info .product-button:hover {
    background-color: #F7B006;
  }

  .product-button {
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    -webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
    clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
    height: 15px;
    font-size: 0.7rem;
    line-height: 14px;
    letter-spacing: 1.2px;
    transition: .2s .1s;
    background-color: #333;
    border: 0 solid;
    overflow: hidden;
  }

.product-button{
    border: 3px solid #fff;
}
  
.product-button:hover {
    cursor: pointer;
    transition: all .3s ease-in;
    padding-right: 30px;
    padding-left: 30px;
}
  
  /* Responsive Design */
  @media (max-width: 800px) {
    .products-section{
      height: 85vh;
      padding: 0px 5%;
    }
    .products-container {
      flex-direction: column;
    }
  
    .product-info, .product-image {
      text-align: center;
    }
  }
  