@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.home_content {
    font-family: "Barlow Condensed", sans-serif;
    height: 90vh;
    padding-top: 10vh;
    overflow: hidden; /* Prevents overflow */
    position: relative; /* Positioning for overlay */
}

.slider {
    display: flex; /* Align slides in a row */
    transition: transform 1s ease-in-out; /* Smooth transition */
    height: 100%; /* Ensure it fills the container */
}

.slide {
    min-width: 100%; /* Each slide takes the full width */
    height: 100%; /* Ensure the slide covers the height */
    background-size: cover;
    background-repeat: no-repeat;
}

.overlay {
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0.5),  rgba(255, 0, 0, 0));
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    position: absolute; /* Position overlay over the slider */
    top: 0;
    left: 0;
}

.home_main_content {
    font-family: "Barlow Condensed", sans-serif;
    margin-top: 8%;
    margin-left: 7%;
    text-align: left;
    
}

.home_main_content h1 {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 50px;
    margin: 20px 0;
    color: #333;
}

.home_main_content p {
    font-family: "Barlow Condensed", sans-serif;
    font-size: 30px;
    margin: 5px 0;
    color: #333;
}

.home_main_content button{
    font-family: "Barlow Condensed", sans-serif;
    font-size: 30px;
    border-radius: 5px;
    width: 221;
    height: 47;
    background-color: #333;
}

button {
    border-radius: .25rem;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 400;
    padding-left: 25px;
    padding-right: 25px;
    color: #fff;
    -webkit-clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
    clip-path: polygon(0 0,0 0,100% 0,100% 0,100% calc(100% - 15px),calc(100% - 15px) 100%,15px 100%,0 100%);
    height: 60px;
    font-size: 0.7rem;
    line-height: 14px;
    letter-spacing: 1.2px;
    transition: .2s .1s;
    background-color: #333;
    border: 0 solid;
    overflow: hidden;
  }

.btn_border{
    border: 3px solid #fff;
}
  
button:hover {
    cursor: pointer;
    transition: all .3s ease-in;
    padding-right: 30px;
    padding-left: 30px;
}


/* Responsive code  */
@media screen and (max-width: 800px){
    .home_content{
        height: 80vh;
    }
    
    .overlay {
        background: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0.6),rgba(255, 255, 255, 0.4),  rgba(255, 0, 0, 0));
        justify-content: end;
    }
    
    .home_main_content {
        margin-left: 0px;
        text-align: center;
        justify-content: end;
        padding: 0px 5% 30px 5%;
    }
    .home_main_content h1{
        font-size: 30px;
        margin: 10px 0px;
    }
    .home_main_content p{
        font-size: 25px;
        /* width: 80%; */
    }
    .home_main_content button{
        font-size: 25px;
        border-radius: 5px;
        background-color: #333;
        padding-left: 15px;
        padding-right: 15px;
        height: 45px;
    }
}