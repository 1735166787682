@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.career {
  font-family: "Barlow Condensed", sans-serif;
  height: 80vh;
  margin-top: 10vh;
}


.career_content{
  margin: 0px 10%;
    color: #000000;
    height: 100%;
    text-align: center;

}

.career_heading{
  height: 15vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  margin-bottom: 10vh;
}
.career_heading h3{
font-size: 48px;
}
.career_heading p{
  font-size: 24px;
  text-align: center;
  }

  .career_section_content{
    height:  45vh;
    display: flex;
    justify-content: space-between;
  }

  .career_left{
    height: 100%;
    width:  20%;
    background-image: url('../Images/career.jpg');
    background-size: cover;
    background-position: right;
  }

  .career_right{
    height: 100%;
    width: 75%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .career_right p{
    font-size: 20px;
    text-align: justify;
  }

  .career_why{
    text-align: left;
    padding: 0px;
  }

  .career_why h4{
    font-size: 28px;
    padding: 0px;
  }
  .career_why li{
    font-size: 20px;
    padding: 0px;
  }

  .career_mail {
    color: #FABC3F;
  }


@media screen and (max-width: 800px){
   .career{
    height: 100vh;
    width: 100%;
   }
   .career_content{
    margin: 0px 5%;
   }

   .career_heading{
    height: 10vh;
   }

   .career_left{
    display: none;
   }
   .career_right{
    width: 100%;
   }
}