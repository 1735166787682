@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.about-us {
  font-family: "Barlow Condensed", sans-serif;
  height: 90vh;
  color: #333;
  padding-top: 9vh;
}

.overlay_content {
  height: auto;
  width: 100%;
  margin-top: 10vh;
  color: #333;
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  flex-direction: column;
  position: absolute;
}

.title {
  height: 9vh;
  padding-top: 5vh;
  width: 100%;
  font-size: 48px;
  color: #333;
  text-align: center;
}

h1 {
  color: #333;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: right;
  margin-bottom: 7px;
  line-height: 70px;
}

p {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: justify;
}

.content {
  height: 35vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.left {
  width: 50%;
  padding-left: 10%;
}

.about_img {
  height: 100%;
  width: 80%;
  background-repeat: no-repeat;
  background-size: cover;
  clip-path: polygon(0 0, 0 0, 100% 0, 100% 0, 100% calc(100% - 55px), calc(100% - 55px) 100%, 55px 100%, 0 100%);
  box-shadow: inset rgba(60, 70, 85, 0.5) 0px 0px 40px 0px, rgba(0, 0, 0, .3) 0px 30px 100px -24px;
}

.right {
  width: 50%;
  text-align: right;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 20px;
}

.right h2{
  text-align: center;
}

.buttons {
  margin-top: 2%;
  height: 15vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18%;
}

.btn_border {
  border: 3px solid #fff;
}

button {
  font-family: "Barlow Condensed", sans-serif;
  border-radius: .25rem;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  clip-path: polygon(0 0, 0 0, 100% 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 15px 100%, 0 100%);
  height: 60px;
  font-size: 18px;
  letter-spacing: 1.2px;
  transition: .2s .1s;
  background-color: #333;
  border: none;
}

button:hover {
  cursor: pointer;
  transition: all .3s ease-in;
  padding-right: 30px;
  padding-left: 30px;
}

button.active {
  background-color: #646464; /* Example active button color */
  color: white;
}

/* Responsive code */
@media screen and (max-width: 800px) {
  .about-us {
    background-position: left;
  }

  .about_img {
    height: 250px;
    width: 100%;
  }

  .left {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 5%;
  }

  .right {
    width: 90%;
    padding-right: 0px;
    padding-left: 5%;
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .buttons {
    margin-top: 110px;
    gap: 3%;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 2rem;
  }

  p {
    font-size: 16px;
  }
}
