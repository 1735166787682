@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  font-family: suse;
  width: 100%;
}

/* Footer section */
.footer {
    background-color: #333;
    color: white;
    text-align: center;

  }

  .footer_sub{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 30px 0px;
  }

  .footer-logo, .contact-info, .address_main{
    width: 20%;
  }

  

  .footer-logo img{
    height: 100px;
    width: auto;
  }
  
  .footer-logo h1 {  
    font-family: "Barlow Condensed", sans-serif;
    color: #FABC3F;
    font-size: 30px;
  }
  
  .footer .contact-info , .address_main{
    /* margin-bottom: 10px; */
    font-family: "Barlow Condensed", sans-serif;
    text-align: justify;
    font-size: 18px;
    letter-spacing: 1px;
    /* padding: 20px; */
  }
  .contact-info{
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .footer .contact-info span ,.address_main span{
    font-family: "Barlow Condensed", sans-serif;
    color: #FABC3F;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  

  .address{
    margin-bottom: 20px;
    font-family: "Barlow Condensed", sans-serif;
  }

  .personal_contact {
    flex-flow: column;
    display: flex;
    /* align-items: center; */
    align-items: flex-start;
    /* justify-content: space-between; */
  }
  
  .footer .social-media svg {
    width: 35px;
    margin: 20px 0px;
  }

  .copywrite{
    padding: 20px 0px;
    background-color: rgb(25, 23, 23);
    color: #FABC3F;
    font-family: "Barlow Condensed", sans-serif;
  }
  .copywrite p{
    text-align: center;
  }
  

  /* Responsive code  */
@media screen and (max-width: 800px){
  .footer_sub{
    flex-direction: column;
    height: 70vh;
    
  }

  .footer-logo, .contact-info{
    width: 80%;
  }

  .address_main{
    width: 80%;
    align-items: flex-start;
    height: fit-content;
  }

  .contact-info{
    /* height: 100%; */
    width: 80%;
    display: flex;
    flex-direction: column;
  }

  .personal_contact{
    width: 100%;
    flex-direction: column;
    align-items:flex-start;
    text-align: start;
  }
  .personal_contact p{
    width: 50%;
  }
}