@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.contact{
    font-family: "Barlow Condensed", sans-serif;
    height: 50vh;
    width: 100%;
    padding-top: 10vh;
}

.contact h3{
    font-size: 48px;
    text-align: center;
    height: 10vh;
}

.map{
    height: 35vh;
    width: 80%;
    margin: 0px 10%;
}
.map iframe{
    height: 100%;
    width:100%;
}

@media screen and (max-width: 800px){
    .map{
        width: 90%;
        margin :0px 5%;
    }
}