@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* General section styling */
.services-section {
  padding: 20px;
  text-align: center;
  margin-top: 10vh;
}

.section-title {
  font-size: 48px;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-family: "Barlow Condensed", sans-serif;

}

/* Cards grid */
.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px 40px;
  padding: 0px 15%;
}

/* Card styling */

/* From Uiverse.io by MikeAndrewDesigner */ 
.e-card {
  margin: 100px auto;
  background: transparent;
  box-shadow: 0px 8px 28px -9px rgba(0,0,0,0.45);
  position: relative;
  width: 240px;
  height: 350px;
  border-radius: 16px;
  overflow: hidden;
  font-family: "Barlow Condensed", sans-serif;
  margin: 0px 20px;
  transition: transform 0.4s cubic-bezier(0.4, 0.0, 0.2, 1), box-shadow 0.4s cubic-bezier(0.4, 0.0, 0.2, 1);
/* background: #333; */

}

.e-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.wave{
  position: absolute;
  width: 540px;
  height: 700px;
  opacity: 0.6;
  left: 0;
  top: 0;
  margin-left: -50%;
  margin-top: -70%;
  background: linear-gradient(744deg,#ffda90,#fabc3fc8 60%,#FABC3F);
}

.icon {
  width: 3em;
  margin-top: -1em;
  padding-bottom: 1em;
}

.infotop {
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: 5.6em;
  left: 0;
  right: 0;
  color: #333;
  font-weight: 600;
  padding: 0px 10px;
}

.infotop img{
  height: 100px;
  width: 100px;
  border-radius: 50%;

}

.infotop h3{
  font-size: 20px;
}

.infotop p{
  font-size: 14px;
  font-weight: 500;
}

.name {
  font-size: 14px;
  font-weight: 100;
  position: relative;
  top: 1em;
  text-transform: lowercase;
}

.wave:nth-child(2),
.wave:nth-child(3) {
  top: 210px;
}

.playing .wave {
  border-radius: 40%;
  animation: wave 3000ms infinite linear;
}

.wave {
  border-radius: 40%;
  animation: wave 55s infinite linear;
}

.playing .wave:nth-child(2) {
  animation-duration: 4000ms;
}

.wave:nth-child(2) {
  animation-duration: 50s;
}

.playing .wave:nth-child(3) {
  animation-duration: 5000ms;
}

.wave:nth-child(3) {
  animation-duration: 45s;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

  /* Responsive code  */
  @media screen and (max-width: 800px){

    

    .e-card{
      height: 370px;
      width: 450px;
      margin: 0px 0px;

    }
  }