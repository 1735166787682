@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* Header section */
.header {
  height: 10vh;
  width: 100%;
  color: white;
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  align-items: center;
}

.navbar {
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 50px;
  align-items: center;
  position: relative;
}

.left_nav {
  display: flex;
  align-items: center;
}

.left_nav h1 {
  font-family: "Barlow Condensed", sans-serif;
  color: #fff;
  font-size: 35px;
}

.logo {
  width: 50px;
  margin-right: 10px;
}

.navbar ul {
  font-size: 20px;
  list-style: none;
  display: flex;
  transition: all 0.3s ease;
}

.navbar ul li a:hover {
  color: #F7B006;
  /* text-decoration: underline; */
  transition: all 0.5s ease;
}



.navbar ul li {
  margin-left: 20px;
}

.navbar ul li a {
  font-family: "Barlow Condensed", sans-serif;
  color: white;
  text-decoration: none;
}

/* Mobile menu icon */
.mobile-menu-icon {
  display: none;
  font-size: 30px;
  color: white;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0px;
}

/* Media query for screens 800px and smaller */
@media (max-width: 800px) {
  .navbar {
      padding: 0 20px;
      background-color: #333;
      display: flex;
      justify-content: space-between;
  }

  .left_nav h1{
    font-size: 27px;
  }

  /* Stack logo and menu vertically */
  .navbar ul {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      flex-direction: column;
      background-color: #333;
      display: none;
      padding: 20px 0;
      text-align: center;
  }

  .navbar ul li {
      margin: 10px 0;
  }

  .nav-open ul {
      display: block;
  }

  /* Show the mobile menu icon */
  .mobile-menu-icon {
      display: block;
      height: 50px;
      width: 50px;
      text-align: center;
  }
}
