/* General styling */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
}

h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}